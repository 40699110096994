.no-orders {
    /* margin: auto;
    width: 50%;
    height: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); */
    text-align: center;
}
    
.no-order-img {
    width: 250px;
    height: 250px;
}
    
.no-orders-text {
    margin: 20px;
    margin-left: 30px;
}
    
.btn-no-order {
    width: 50% !important;
}
    
@media (max-width: 1200px) {
    .no-order-img {
        width: 180px;
        height: 180px;
    }
    
    /* .btn-no-order {
        width: 90% !important;
        margin-left: 5px !important;
    }
    
    .no-orders {
        width: 80%;
    } */
}