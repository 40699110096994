@import "../variables.scss";

.text {
  &--active {
    color: $grey-900!important;
    font-weight: 900!important;
  }
  &--inactive {
    color: $grey-600!important;
    margin-right: 0.5rem !important;
  }

  &--red{
    color: $calma-red!important;
    margin-right: 0.5rem !important;
  }
}