.spacer {
  &__0-5 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  &__1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &__2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  &__3 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  &__4 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  &__5 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
}
