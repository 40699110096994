$primary:#f44336;
$primary-light: #ef5350;
$primary-dark: #d32f2f;

$secondary:#212121;
$secondary-light: #424242;
$secondary-dark: #000000;

$grey-50: #fafafa;
$grey-100: #f5f5f5;
$grey-200: #eeeeee;
$grey-300: #e0e0e0;
$grey-400: #bdbdbd;
$grey-500: #bdbdbd;
$grey-600: #757575;
$grey-700: #616161;
$grey-800: #424242;
$grey-900: #212121;

$calma-red: #ED4436;
$calma-grey: #95A3AB;
$calma-black: #000000;
$calma-yellow: #F4B824;