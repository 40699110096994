hr {
  border: 2px solid #f2bd82;
  border-top: none;
  width: 50%;
}

.row-wrapper {
  padding: 0.5rem 0.5rem;
}

.checkout-box {
  background-color: white;
  border-radius: 10px;
  height: 90%;
  margin-top: 24px;
}

.checkout-item-img {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.breadcrumb {
  background-color: #f2bd82 !important;
}

.breadcrumb-align {
  margin-left: 45px;
  margin-bottom: 20px;
  margin-top: 17px;
}

.breadcrumb-link-custom {
  color: #f8e9d9;
}

.breadcrumb-link-custom:hover {
  color: #b25d30 !important;
}

/*Checkout button styling*/
.btn-check {
  width: 50%;
  margin: auto;
  display: block;
}

.btn-back-to-menu {
  min-height: 48px;
}

.btn-check:disabled {
  color: black;
  background-color: grey !important;
}

/* To position the click to edit button */
.edit-button {
  position: absolute;
  right: 0;
}

.edit-button > button {
  padding: 0 10px;
}

/* Bootstrap button styles override */
.btn-primary {
  background-color: #b25d30 !important;
  border: none !important;
}

.btn-secondary {
  background-color: #b25d30 !important;
  border: none !important;
}

.btn-outline-primary {
  position: absolute !important;
  right: 10px;
  font-size: small;
  color: #f2bd82;
  border: none !important;
}

.btn-outline-primary:hover {
  background-color: white !important;
  color: #f2bd82 !important;
}

.btn-outline-primary:disabled {
  color: #f2bd82 !important;
}

.space {
  width: 60px;
}

/* To align the word quantity in the cart items table */
.quantity {
  margin-left: 14px;
}

/* Adress container styling */
.address-styling {
  background-color: white;
  margin-bottom: 15px;
  border-radius: 10px;
}

.address-container {
  position: relative;
  padding-right: 0;
}

.add-address-button {
  position: absolute;
  right: 25px;
}

.radio-button {
  padding: 0 10px;
  margin-top: 0;
}

/* @media (max-width: 600px) {
  .radio-button {
    padding: 0 10px;
    margin-top: 0;
  }
} */
/* User Address Information layout */
.info {
  margin-top: 10px;
}

.user-name {
  font-size: larger;
}

.user-address {
  color: grey;
  font-size: 14px;
}

a:hover {
  color: #b48972 !important;
}

@media (max-width: 1200px) {
  .checkout-box {
    height: 94%;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .edit-button {
    right: -20px;
  }
  .checkout-div {
    margin-top: 50px;
  }

  /* .ship-add {
    margin-top: 60px;
  } */
}

@media (max-width: 990px) {
  .ship-add {
    margin-top: 10px;
  }
}

.address-heading {
  color: #b25d30;
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: center;
}

.grid_container {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1.5fr 1fr 0.5fr;
  grid-template-rows: auto;
  gap: 5px 10px;
  grid-template-areas: '. . . . .';
}

.grid_cell {
  padding: 1rem 0.3rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/* Chrome, Safari, Edge, Opera */
.quantity-input::-webkit-outer-spin-button,
.quantity-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.quantity-input[type='number'] {
  -moz-appearance: textfield;
}

.width-full {
  width: 100%;
}

.price-text {
  padding-top: 1rem;
}
