@import "../../styles/base.scss";

.container {
  &__grid {
    height: 100%;
  }

  &__side-image {
    background-image: url(https://source.unsplash.com/random/?food);
    background-repeat: 'no-repeat';
    background-size: cover;
    background-position: center;
  }

  &__form {
    &__wrapper {
      height: 100%;
    }
    padding: 3rem;
    width: 100%;
  }
}

.inline-link{
  color:$primary;
  margin-left: 2px;
  margin-right: 2px;
}
