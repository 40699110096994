.Carousel-indicators-2 {
  position: absolute !important;
  bottom: 10px !important;
}

.Carousel-indicator-3 {
  color: $secondary!important;
  font-size: 10px!important;
}

.Carousel-active-4{
  color: $primary!important;
}
